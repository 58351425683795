'use client'

import { useTranslations } from 'next-intl'
import { type ComponentProps } from 'react'

const ONE_TRUST_BUTTON_SELECTOR = '#ot-sdk-btn-floating button'

export function CookieSettingsLink(props: ComponentProps<'a'>) {
  const t = useTranslations('common')

  function handleClick() {
    const oneTrustButton = document.querySelector(ONE_TRUST_BUTTON_SELECTOR) as Maybe<HTMLButtonElement>

    // the build-in floating button is visually hidden (in root-layout.scss),
    // so we need to click it programmatically from our own link
    oneTrustButton?.click()
  }

  return (
    // eslint-disable-next-line jsx-a11y/anchor-is-valid
    <a {...props} href="#" onClick={handleClick}>
      {t('cookieSettings')}
    </a>
  )
}
