'use client'

import { MenuItem, Select, type SelectProps } from '@mui/material'
import clsx from 'clsx'
import { useRouter } from 'next/navigation'
import { useLocale } from 'next-intl'
import React from 'react'

import { trpc } from '@/modules/api/trpc/lib/browser'

import classes from './locale-switcher.module.scss'
import { LOCALES } from '../../config/consts'

const localeOptions = Object.entries(LOCALES).map(([key, value]) => ({
  value: key,
  label: value,
}))

export function LocaleSwitcher({ className, ...restProps }: SelectProps) {
  const locale = useLocale()
  const router = useRouter()
  const utils = trpc.useUtils()
  const changeLocaleMutation = trpc.changeLocale.useMutation({
    onSuccess: async () => {
      await utils.invalidate()
      router.refresh()
    },
  })

  return (
    <Select
      size="small"
      className={clsx(classes.localeSwitcher, className)}
      value={locale}
      onChange={(event) => {
        changeLocaleMutation.mutate({ locale: event.target.value as string })
      }}
      {...restProps}
    >
      {localeOptions.map((option) => (
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      ))}
    </Select>
  )
}
