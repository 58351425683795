export const LOCALES = {
  en: 'English',
  'fr-FR': 'Français',
  'pt-BR': 'Português',
  'it-IT': 'Italiano',
  'es-ES': 'Español',
  'ru-RU': 'Русский',
  'zh-CN': '中文 (简化字)',
  'pl-PL': 'Polski',
  'de-DE': 'Deutsch',
} as const

export const HAMMERTIME_LOCALE_MAP: Record<string, Locale> = {
  en: 'en',
  fr: 'fr-FR',
  pt: 'pt-BR',
  it: 'it-IT',
  es: 'es-ES',
  ru: 'ru-RU',
  zh: 'zh-CN',
  pl: 'pl-PL',
  de: 'de-DE',
}

export type Locale = keyof typeof LOCALES

export const DEFAULT_LOCALE: Locale = 'en'

export const LOCALE_COOKIE_NAME = 'LOCALE'

export const SIGN_UP_LOCALE_UPDATE_COOKIE_NAME = '_hammertime_sign_up_locale_update'
