'use client'

import { useTranslations } from 'next-intl'
import { useCallback, useEffect } from 'react'

import { useAnalytics } from '@/modules/analytics/hooks/use-analytics'
import { Button } from '@/modules/ui/components/button'
import { type ButtonProps } from '@/modules/ui/components/button/button'
import { useModalDispatch } from '@/modules/ui/utils/modal-dispatch'

type LoginButtonProps = ButtonProps & {
  readonly shouldOpenOnMount?: boolean
  readonly analyticsSource: 'homepage' | 'sign_up_location_page' | 'reactivate_flash_message'
}

export function LoginButton({ shouldOpenOnMount, analyticsSource, ...props }: LoginButtonProps) {
  const t = useTranslations('auth')
  const openModal = useModalDispatch()
  const { logEvent } = useAnalytics()

  const handleOpenModal = useCallback(() => {
    openModal('login')
    logEvent('login_select', { source: analyticsSource })
  }, [openModal, logEvent, analyticsSource])

  useEffect(() => {
    if (shouldOpenOnMount) {
      handleOpenModal()
    }
  }, [shouldOpenOnMount, handleOpenModal])

  return (
    <Button onClick={handleOpenModal} {...props}>
      {t('logIn')}
    </Button>
  )
}
