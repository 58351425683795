'use client'

import { Alert, Collapse } from '@mui/material'
import { useAtom } from 'jotai'
import React from 'react'

import { useAnalytics } from '@/modules/analytics/hooks/use-analytics'

import { mainAlertAtom } from './atoms'
import classes from './main-alert.module.scss'

const ALERT_DURATION_MS = 7000

export function MainAlert() {
  const [mainAlert, setMainAlert] = useAtom(mainAlertAtom)
  const [shouldDisplay, setShouldDisplay] = React.useState(false)

  const { logEvent } = useAnalytics()

  function handleExited() {
    setMainAlert(null)
  }

  React.useEffect(() => {
    if (mainAlert) {
      setShouldDisplay(true)
      logEvent('alert_show', { message: mainAlert.message, type: 'alert' })

      if (!mainAlert.isAutoCloseDisabled) {
        setTimeout(() => {
          setShouldDisplay(false)
          mainAlert.onClose?.()
        }, ALERT_DURATION_MS)
      }
    }
  }, [mainAlert, logEvent])

  return (
    <Collapse in={shouldDisplay} onExited={handleExited}>
      <Alert severity={mainAlert?.severity} className={classes.mainAlert}>
        {mainAlert?.message}
      </Alert>
    </Collapse>
  )
}
